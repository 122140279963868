import { Fetcher, Fetcher as FetcherSpirit, Route, Token, Token as TokenSpirit } from "../sdk/dist";

import { Configuration } from "./config";
import {
  ContractName,
  TokenStat,
  AllocationTime,
  LPStat,
  Bank,
  PoolStats,
  SkullSwapperStat,
} from "./types";
import { BigNumber, Contract, ethers, EventFilter } from "ethers";
import { decimalToBalance } from "./ether-utils";
import { TransactionResponse } from "@ethersproject/providers";
import ERC20 from "./ERC20";
import {
  getFullDisplayBalance,
  getDisplayBalance,
} from "../utils/formatBalance";
import { getDefaultProvider } from "../utils/provider";
import IUniswapV2PairABI from "./IUniswapV2Pair.abi.json";
import config, { bankDefinitions } from "../config";
import { genesisDefinitions } from "../config";

import moment from "moment";
import { parseUnits } from "ethers/lib/utils";
import {
  WETH_TICKER,
  UNISWAPV2_ROUTER_ADDR,
  TOMB_TICKER,
} from "../utils/constants";
import { Signer } from "@ethersproject/abstract-signer";
import { Provider } from "@ethersproject/abstract-provider";
import { error } from "console";
/**
 * An API module of TombStone Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class TombFinance {
  myAccount: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  masonryVersionOfUser?: string;

  TOMBWETH_LP: Contract;
  GRAVE: ERC20;
  SKULL: ERC20;
  TBOND: ERC20;
  WETH: ERC20;

  constructor(cfg: Configuration) {
    const { deployments, externalTokens } = cfg;
    const provider = getDefaultProvider();

    // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      this.contracts[name] = new Contract(
        deployment.address,
        deployment.abi,
        provider
      );
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(
        address,
        provider,
        symbol,
        decimal
      );
    }
    this.GRAVE = new ERC20(deployments.grave.address, provider, "GRAVE");
    this.SKULL = new ERC20(deployments.Skull.address, provider, "SKULL");
    this.TBOND = new ERC20(deployments.tBond.address, provider, "GBOND");
    this.WETH = this.externalTokens["WETH"];

    // Uniswap V2 Pair
    this.TOMBWETH_LP = new Contract(
      externalTokens["GRAVE-WETH-LP"][0],
      IUniswapV2PairABI,
      provider
    );
    

    this.config = cfg;
    this.provider = provider;
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(
      provider,
      this.config.chainId
    );
    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = [
      this.GRAVE,
      this.SKULL,
      this.TBOND,
      ...Object.values(this.externalTokens),
    ];

    console.log("signer", this.signer);

    try {
      for (const token of tokens) {
        token.connect(this.signer as Signer | Provider);
      }
    } catch {
      console.log("error", this.signer);
    }
    this.TOMBWETH_LP = this.TOMBWETH_LP.connect(this.signer);
    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
    this.fetchMasonryVersionOfUser()
      .then((version) => (this.masonryVersionOfUser = version))
      .catch((err) => {
        console.error(`Failed to fetch masonry version: ${err.stack}`);
        this.masonryVersionOfUser = "latest";
      });
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //===================FROM SPOOKY TO DISPLAY =========================
  //=========================IN HOME PAGE==============================
  //===================================================================

  async getGraveStat(): Promise<TokenStat> {
    const { GraveWethLPGraveRewardPool, GenesisWethRewardPool } = this.contracts;
   
    const supply = await this.GRAVE.totalSupply();
    const tombRewardPoolSupply = await this.GRAVE.balanceOf(
      GenesisWethRewardPool.address
    );
    
    const tombRewardPoolSupply2 = await this.GRAVE.balanceOf(
      GraveWethLPGraveRewardPool.address
    );

    const tombCirculatingSupply = supply
      .sub(tombRewardPoolSupply)
      .sub(tombRewardPoolSupply2);
    const priceInWETH = await this.getTokenPriceFromPancakeswap(this.GRAVE);
    const priceOfOneWETH = await this.getWETHPriceFromPancakeswap();
    const priceOfTombInDollars = (
      Number(priceInWETH) * Number(priceOfOneWETH)
    ).toFixed(2);
   

    return {
      tokenInFtm: priceInWETH,
      priceInDollars: priceOfTombInDollars,
      totalSupply: getDisplayBalance(supply, this.GRAVE.decimal, ),
      circulatingSupply: getDisplayBalance(
        tombCirculatingSupply,
        this.GRAVE.decimal,
        0
      ),
    };
  }

  /**
   * Calculates various stats for the requested LP
   * @param name of the LP token to load stats for
   * @returns
   */
  async getLPStat(name: string): Promise<LPStat> {
    const lpToken = this.externalTokens[name];
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18);
    const token0 = name.startsWith("GRAVE") ? this.GRAVE : this.SKULL;
    
    const isTomb = name.startsWith("GRAVE");
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, 18);
    const ethAmountBN = await this.WETH.balanceOf(lpToken.address);
    const ftmAmount = getDisplayBalance(ethAmountBN, 18);
    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const ftmAmountInOneLP = Number(ftmAmount) / Number(lpTokenSupply);
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isTomb);
    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(2).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice))
      .toFixed(2)
      .toString();
    return {
      tokenAmount: tokenAmountInOneLP.toFixed(2).toString(),
      ftmAmount: ftmAmountInOneLP.toFixed(2).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(4).toString(),
    };
  }

  /**
   * Use this method to get price for Tomb
   * @returns TokenStat for TBOND
   * priceInWETH
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async getBondStat(): Promise<TokenStat> {
    const { Treasury } = this.contracts;
    const tombStat = await this.getGraveStat();
    const bondTombRatioBN = await Treasury.getBondPremiumRate();
    const modifier = bondTombRatioBN / 1e18 > 1 ? bondTombRatioBN / 1e18 : 1;
    const bondPriceInWETH = (Number(tombStat.tokenInFtm) * modifier).toFixed(2);
    const priceOfTBondInDollars = (
      Number(tombStat.priceInDollars) * modifier
    ).toFixed(2);
    const supply = await this.TBOND.displayedTotalSupply();
    return {
      tokenInFtm: bondPriceInWETH,
      priceInDollars: priceOfTBondInDollars,
      totalSupply: supply,
      circulatingSupply: supply,
    };
  }

  /**
   * @returns TokenStat for SKULL
   * priceInWETH
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async geSkullStat(): Promise<TokenStat> {
    const { GraveWethLPSkullRewardPool } = this.contracts;

    const supply = await this.SKULL.totalSupply();

    const priceInWETH = await this.getTokenPriceFromPancakeswap(this.SKULL);
    const tombRewardPoolSupply = await this.SKULL.balanceOf(
      GraveWethLPSkullRewardPool.address
    );
    const SkullCirculatingSupply = supply.sub(tombRewardPoolSupply);
    const priceOfOneWETH = await this.getWETHPriceFromPancakeswap();
    const priceOfSharesInDollars = (
      Number(priceInWETH) * Number(priceOfOneWETH)
    ).toFixed(2);

    return {
      tokenInFtm: priceInWETH,
      priceInDollars: priceOfSharesInDollars,
      totalSupply: getDisplayBalance(supply, this.SKULL.decimal, 0),
      circulatingSupply: getDisplayBalance(
        SkullCirculatingSupply,
        this.SKULL.decimal,
        0
      ),
    };
  }

  async getGraveStatInEstimatedTWAP(): Promise<TokenStat> {
    const { SeigniorageOracle, TombFtmRewardPool } = this.contracts;
    const expectedPrice = await SeigniorageOracle.twap(
      this.GRAVE.address,
      ethers.utils.parseEther("1")
    );

    const supply = await this.GRAVE.totalSupply();
    const tombRewardPoolSupply = await this.GRAVE.balanceOf(
      TombFtmRewardPool.address
    );
    const tombCirculatingSupply = supply.sub(tombRewardPoolSupply);
    return {
      tokenInFtm: getDisplayBalance(expectedPrice),
      priceInDollars: getDisplayBalance(expectedPrice),
      totalSupply: getDisplayBalance(supply, this.GRAVE.decimal, 0),
      circulatingSupply: getDisplayBalance(
        tombCirculatingSupply,
        this.GRAVE.decimal,
        0
      ),
    };
  }

  async getTombPriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getGraveUpdatedPrice();
  }

  async getBondsPurchasable(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBurnableTombLeft();
  }

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns
   */
  async getPoolAPRs(bank: Bank): Promise<PoolStats> {
    if (this.myAccount === undefined) return;
    const depositToken = bank.depositToken;
    const poolContract = this.contracts[bank.contract];
    const depositTokenPrice = await this.getDepositTokenPriceInDollars(
      bank.depositTokenName,
      depositToken
    );
    const stakeInPool = await depositToken.balanceOf(bank.address);
    const TVL =
      Number(depositTokenPrice) *
      Number(getDisplayBalance(stakeInPool, depositToken.decimal));
    const stat =
      bank.earnTokenName === "GRAVE"
        ? await this.getGraveStat()
        : await this.geSkullStat();
    const tokenPerSecond = await this.getTokenPerSecond(
      bank.earnTokenName,
      bank.contract,
      poolContract,
      bank.depositTokenName
    );

    const tokenPerHour = tokenPerSecond.mul(60).mul(60);
    const totalRewardPricePerYear =
      Number(stat.priceInDollars) *
      Number(getDisplayBalance(tokenPerHour.mul(24).mul(365)));
    const totalRewardPricePerDay =
      Number(stat.priceInDollars) *
      Number(getDisplayBalance(tokenPerHour.mul(24)));
    const totalStakingTokenInPool =
      Number(depositTokenPrice) *
      Number(getDisplayBalance(stakeInPool, depositToken.decimal));
    const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
    const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;
    return {
      dailyAPR: dailyAPR.toFixed(2).toString(),
      yearlyAPR: yearlyAPR.toFixed(2).toString(),
      TVL: TVL.toFixed(2).toString(),
    };
  }

  /**
   * Method to return the amount of tokens the pool yields per second
   * @param earnTokenName the name of the token that the pool is earning
   * @param contractName the contract of the pool/bank
   * @param poolContract the actual contract of the pool
   * @returns
   */
  async getTokenPerSecond(
    earnTokenName: string,
    contractName: string,
    poolContract: Contract,
    depositTokenName: string
  ) {
    if (earnTokenName === "GRAVE") {
      if (!contractName.endsWith("GraveRewardPool")) {
        const rewardPerSecond = (await poolContract.GravePerSecond()).mul(20);
        if (depositTokenName === "WETH") {
          return rewardPerSecond.mul(5000).div(10000).div(24); // 6000
        } else if (depositTokenName === 'USDbC') {
             return rewardPerSecond.mul(2500).div(10000).div(24); // 2500
          } else if (depositTokenName === 'DAI') {
           return rewardPerSecond.mul(2500).div(10000).div(24); // 1000
           } /*  else if (depositTokenName === 'MIM') {
          return rewardPerSecond.mul(1500).div(11000).div(24); // 1000
           }*/
        return rewardPerSecond.div(1);
      }
      const poolStartTime = await poolContract.poolStartTime();
      const startDateTime = new Date(poolStartTime.toNumber() * 1000);
      const FOUR_DAYS = 4 * 24 * 60 * 60 * 1000;
      if (Date.now() - startDateTime.getTime() > FOUR_DAYS) {
        return await poolContract.epochGravePerSecond(1);
      }
      return await poolContract.epochGravePerSecond(0);
    }
    const rewardPerSecond = await poolContract.skullPerSecond();
    if (depositTokenName.startsWith("GRAVE-WETH")) {
      return rewardPerSecond.mul(2500).div(5000);
    } else if (depositTokenName.startsWith("SKULL-WETH")) {
      return rewardPerSecond.mul(2500).div(5000);
    } else {
    //  return rewardPerSecond.mul(5500).div(59500);
    }
  }

  /*
   * Method to calculate the tokenPrice of the deposited asset in a pool/bank
   * If the deposited token is an LP it will find the price of its pieces
   * @param tokenName
   * @param pool
   * @param token
   * @returns
   */
  async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
    let tokenPrice;
    const priceOfOneFtmInDollars = await this.getWETHPriceFromPancakeswap();
    if (tokenName === "WETH") {
      tokenPrice = priceOfOneFtmInDollars;
    } else {
      if (tokenName === "GRAVE-WETH-LP") {
        tokenPrice = await this.getLPTokenPrice(token, this.GRAVE, true);
      } else if (tokenName === "SKULL-WETH-LP") {
        tokenPrice = await this.getLPTokenPrice(token, this.SKULL, false);
      } else if (tokenName === "GRAVE-SKULL-LP") {
        tokenPrice = await this.getLPTokenPrice(token, this.GRAVE, true);
      } else if (tokenName === "SHIBA") {
        tokenPrice = await this.getTokenPriceFromSpiritswap(token);
      } else {
        tokenPrice = await this.getTokenPriceFromPancakeswap(token);
        tokenPrice = (
          Number(tokenPrice) * Number(priceOfOneFtmInDollars)
        ).toString();
      }
    }
    return tokenPrice;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //=========================== END ===================================
  //===================================================================

  async getCurrentEpoch(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.epoch();
  }

  async getBondOraclePriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBondPremiumRate();
  }

  /**
   * Buy bonds with cash.
   * @param amount amount of cash to purchase bonds with.
   */
  async buyBonds(amount: string | number): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const treasuryTombPrice = await Treasury.getGravePrice();
    return await Treasury.buyBonds(decimalToBalance(amount), treasuryTombPrice);
  }

  /**
   * Redeem bonds for cash.
   * @param amount amount of bonds to redeem.
   */
  async redeemBonds(amount: string): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const priceForTomb = await Treasury.getGravePrice();
    return await Treasury.redeemBonds(decimalToBalance(amount), priceForTomb);
  }

  // async getTotalValueLocked(): Promise<Number> {

  //   let totalValue = 0;
  //   for (const bankInfo of Object.values(bankDefinitions)) {
  //     const pool = this.contracts[bankInfo.contract];
  //     const token = this.externalTokens[bankInfo.depositTokenName];
  //     const tokenPrice = await this.getDepositTokenPriceInDollars(bankInfo.depositTokenName, token);
  //     const tokenAmountInPool = await token.balanceOf(pool.address);
  //     const value = Number(getDisplayBalance(tokenAmountInPool, token.decimal)) * Number(tokenPrice);
  //     const poolValue = Number.isNaN(value) ? 0 : value;
  //     totalValue += poolValue;
  //   }

  //   return totalValue;
  // }

  async getTotalValueLocked(): Promise<Number> {
    try {
    let totalValue = 0;
    for (const bankInfo of Object.values(bankDefinitions)) {
      const pool = this.contracts[bankInfo.contract];
      const token = this.externalTokens[bankInfo.depositTokenName];
      const tokenPrice = await this.getDepositTokenPriceInDollars(
        bankInfo.depositTokenName,
        token
      );

      const tokenAmountInPool = await token.balanceOf(pool.address);
      const value =
        Number(getDisplayBalance(tokenAmountInPool, token.decimal)) *
        Number(tokenPrice);    
      const poolValue = Number.isNaN(value) ? 0 : value;
      totalValue += poolValue;
      
    }
    /** 
    const SKULLPrice = (await this.geSkullStat()).priceInDollars;
    const masonrySkullBalanceOf = await this.SKULL.balanceOf(
      this.currentMasonry().address
    );
    const masonryTVL =
      Number(getDisplayBalance(masonrySkullBalanceOf, this.SKULL.decimal)) *
      Number(SKULLPrice);
      */
    return totalValue;
  } catch (error) {
}
  } 

  /**
   * Calculates the price of an LP token
   * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
   * @param lpToken the token under calculation
   * @param token the token pair used as reference (the other one would be WETH in most cases)
   * @param isTomb sanity check for usage of tomb token or Skull
   * @returns price of the LP token
   */
  async getLPTokenPrice(
    lpToken: ERC20,
    token: ERC20,
    isTomb: boolean
  ): Promise<string> {
    const totalSupply = getFullDisplayBalance(
      await lpToken.totalSupply(),
      lpToken.decimal
    );
    //Get amount of tokenA
    const tokenSupply = getFullDisplayBalance(
      await token.balanceOf(lpToken.address),
      token.decimal
    );
    const stat =
      isTomb === true ? await this.getGraveStat() : await this.geSkullStat();
    const priceOfToken = stat.priceInDollars;
    const tokenInLP = Number(tokenSupply) / Number(totalSupply);
    const tokenPrice = (Number(priceOfToken) * tokenInLP * 2) //We multiply by 2 since half the price of the lp token is the price of each piece of the pair. So twice gives the total
      .toString();
    return tokenPrice;
  }

  async earnedFromBank(
    poolName: ContractName,
    earnTokenName: String,
    poolId: Number,
    account = this.myAccount
  ): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      if (earnTokenName === "GRAVE") {
        return await pool.pendingGrave(poolId, account);
      } else {
        return await pool.pendingSkull(poolId, account);
      }
    } catch (err) {
      console.error(`Failed to call earned() on pool ${pool.address}`);
      return BigNumber.from(0);
    }
  }

  async stakedBalanceOnBank(
    poolName: ContractName,
    poolId: Number,
    account = this.myAccount
  ): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      let userInfo = await pool.userInfo(poolId, account);
      return await userInfo.amount;
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}`);
      return BigNumber.from(0);
    }
  }

  /**
   * Deposits token to given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async stake(
    poolName: ContractName,
    poolId: Number,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.deposit(poolId, amount);
  }

  /**
   * Withdraws token from given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async unstake(
    poolName: ContractName,
    poolId: Number,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.withdraw(poolId, amount);
  }

  /**
   * Transfers earned token reward from given pool to my account.
   */
  async harvest(
    poolName: ContractName,
    poolId: Number
  ): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
    return await pool.withdraw(poolId, 0);
  }

  /**
   * Harvests and withdraws deposited tokens from the pool.
   */
  async exit(
    poolName: ContractName,
    poolId: Number,
    account = this.myAccount
  ): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    let userInfo = await pool.userInfo(poolId, account);
    return await pool.withdraw(poolId, userInfo.amount);
  }

  async fetchMasonryVersionOfUser(): Promise<string> {
    return "latest";
  }

  currentMasonry(): Contract {
    if (!this.masonryVersionOfUser) {
      //throw new Error('you must unlock the wallet to continue.');
    }
    return this.contracts.Masonry;
  }

  isOldMasonryMember(): boolean {
    return this.masonryVersionOfUser !== "latest";
  }

  async getTokenPriceFromPancakeswap(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;
    const { WETH } = this.config.externalTokens;

    const wftm = new Token(chainId, WETH[0], WETH[1]);
    const token = new Token(
      chainId,
      tokenContract.address,
      tokenContract.decimal,
      tokenContract.symbol
    );
  
    
   
    try {
      const wftmToToken = await Fetcher.fetchPairData(
        wftm,
        token,
        this.provider
      );
      
      const priceInBUSD = new Route([wftmToToken], token);

      return priceInBUSD.midPrice.toFixed(4);
    } catch (err) {
      console.error(
        `Failed to fetch token price of ${tokenContract.symbol}: ${err}`
      );
    }
  }

  async getTokenPriceFromSpiritswap(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;

    const { WETH } = this.externalTokens;

    const wftm = new TokenSpirit(chainId as any, WETH.address, WETH.decimal);
    const token = new TokenSpirit(
      chainId as any,
      tokenContract.address,
      tokenContract.decimal,
      tokenContract.symbol
    );
    try {
      const wftmToToken = await FetcherSpirit.fetchPairData(
        wftm,
        token,
        this.provider
      );
      const liquidityToken = wftmToToken.liquidityToken;
      let ftmBalanceInLP = await WETH.balanceOf(liquidityToken.address);
      let ftmAmount = Number(
        getFullDisplayBalance(ftmBalanceInLP, WETH.decimal)
      );
      let shibaBalanceInLP = await tokenContract.balanceOf(
        liquidityToken.address
      );
      let shibaAmount = Number(
        getFullDisplayBalance(shibaBalanceInLP, tokenContract.decimal)
      );
      const priceOfOneFtmInDollars = await this.getWETHPriceFromPancakeswap();
      let priceOfShiba =
        (ftmAmount / shibaAmount) * Number(priceOfOneFtmInDollars);
      return priceOfShiba.toString();
    } catch (err) {
      console.error(
        `Failed to fetch token price of ${tokenContract.symbol}: ${err}`
      );
    }
  }

  async getWETHPriceFromPancakeswap(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WETH, DAI } = this.externalTokens;
    try {
      const fusdt_wftm_lp_pair = this.externalTokens["DAI-WETH-LP"];
      let ftm_amount_BN = await WETH.balanceOf(fusdt_wftm_lp_pair.address);
     
      let ftm_amount = Number(
        getFullDisplayBalance(ftm_amount_BN, WETH.decimal)
      );
      
      let fusdt_amount_BN = await DAI.balanceOf(fusdt_wftm_lp_pair.address);
  
      let fusdt_amount = Number(
        getFullDisplayBalance(fusdt_amount_BN, DAI.decimal)
      );

      return (fusdt_amount / ftm_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of WETH: ${err}`);
    }
  }

  //===================================================================
  //===================================================================
  //===================== MASONRY METHODS =============================
  //===================================================================
  //===================================================================

  async getMasonryAPR() {
    const Masonry = this.currentMasonry();
    const latestSnapshotIndex = await Masonry.latestSnapshotIndex();
    const lastHistory = await Masonry.masonryHistory(latestSnapshotIndex);

    const lastRewardsReceived = lastHistory[1];

    const SKULLPrice = (await this.geSkullStat()).priceInDollars;
    const TOMBPrice = (await this.getGraveStat()).priceInDollars;
    const epochRewardsPerShare = lastRewardsReceived / 1e18;

    //Mgod formula
    const amountOfRewardsPerDay = epochRewardsPerShare * Number(TOMBPrice) * 4;
    const masonrySkullBalanceOf = await this.SKULL.balanceOf(Masonry.address);
    const masonryTVL =
      Number(getDisplayBalance(masonrySkullBalanceOf, this.SKULL.decimal)) *
      Number(SKULLPrice);
    const realAPR = ((amountOfRewardsPerDay * 100) / masonryTVL) * 365;
    return realAPR;
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserClaimRewardFromMasonry(): Promise<boolean> {
    const Masonry = this.currentMasonry();
    return await Masonry.canClaimReward(this.myAccount);
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserUnstakeFromMasonry(): Promise<boolean> {
    const Masonry = this.currentMasonry();
    const canWithdraw = await Masonry.canWithdraw(this.myAccount);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    const notStaked =
      Number(getDisplayBalance(stakedAmount, this.SKULL.decimal)) === 0;
    const result = notStaked ? true : canWithdraw;
    return result;
  }

  async timeUntilClaimRewardFromMasonry(): Promise<BigNumber> {
    // const Masonry = this.currentMasonry();
    // const mason = await Masonry.masons(this.myAccount);
    return BigNumber.from(0);
  }

  async getTotalStakedInMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    return await Masonry.totalSupply();
  }

  async stakeShareToMasonry(amount: string): Promise<TransactionResponse> {
    if (this.isOldMasonryMember()) {
      throw new Error(
        "you're using old masonry. please withdraw and deposit the SKULL again."
      );
    }
    const Masonry = this.currentMasonry();
    return await Masonry.stake(decimalToBalance(amount));
  }

  async getStakedSharesOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === "v1") {
      return await Masonry.geSkullOf(this.myAccount);
    }
    return await Masonry.balanceOf(this.myAccount);
  }

  async getEarningsOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === "v1") {
      return await Masonry.getCashEarningsOf(this.myAccount);
    }
    return await Masonry.earned(this.myAccount);
  }

  async withdrawShareFromMasonry(amount: string): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.withdraw(decimalToBalance(amount));
  }

  async harvestCashFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === "v1") {
      return await Masonry.claimDividends();
    }
    return await Masonry.claimReward();
  }

  async exitFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.exit();
  }

  async getTreasuryNextAllocationTime(): Promise<AllocationTime> {
    const { Treasury } = this.contracts;
    const nextEpochTimestamp: BigNumber = await Treasury.nextEpochPoint();
    const nextAllocation = new Date(nextEpochTimestamp.mul(1000).toNumber());
    const prevAllocation = new Date(Date.now());

    return { from: prevAllocation, to: nextAllocation };
  }
  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to claim
   * their reward from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserClaimRewardTime(): Promise<AllocationTime> {
    const { Masonry, Treasury } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint(); //in unix timestamp
    const currentEpoch = await Masonry.epoch();
    const mason = await Masonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await Treasury.PERIOD();
    const periodInHours = period / 60 / 60; // 6 hours, period is displayed in seconds which is 21600
    const rewardLockupEpochs = await Masonry.rewardLockupEpochs();
    const targetEpochForClaimUnlock =
      Number(startTimeEpoch) + Number(rewardLockupEpochs);

    const fromDate = new Date(Date.now());
    if (targetEpochForClaimUnlock - currentEpoch <= 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - currentEpoch - 1;
      const endDate = moment(toDate)
        .add(delta * periodInHours, "hours")
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to unstake
   * from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserUnstakeTime(): Promise<AllocationTime> {
    const { Masonry, Treasury } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint();
    const currentEpoch = await Masonry.epoch();
    const mason = await Masonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await Treasury.PERIOD();
    const PeriodInHours = period / 60 / 60;
    const withdrawLockupEpochs = await Masonry.withdrawLockupEpochs();
    const fromDate = new Date(Date.now());
    const targetEpochForClaimUnlock =
      Number(startTimeEpoch) + Number(withdrawLockupEpochs);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    if (
      currentEpoch <= targetEpochForClaimUnlock &&
      Number(stakedAmount) === 0
    ) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
      const endDate = moment(toDate)
        .add(delta * PeriodInHours, "hours")
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  async watchAssetInMetamask(assetName: string): Promise<boolean> {
    const { ethereum } = window as any;
    if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
      let asset;
      let assetUrl;
      if (assetName === "GRAVE") {
        asset = this.GRAVE;
        assetUrl = "https://tomb.finance/presskit/tomb_icon_noBG.png";
      } else if (assetName === "SKULL") {
        asset = this.SKULL;
        assetUrl = "https://tomb.finance/presskit/Skull_icon_noBG.png";
      } else if (assetName === "TBOND") {
        asset = this.TBOND;
        assetUrl = "https://tomb.finance/presskit/tbond_icon_noBG.png";
      }
      await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: asset.address,
            symbol: asset.symbol,
            decimals: 18,
            image: assetUrl,
          },
        },
      });
    }
    return true;
  }

  async provideTombFtmLP(
    ftmAmount: string,
    tombAmount: BigNumber
  ): Promise<TransactionResponse> {
    const { TaxOffice } = this.contracts;
    let overrides = {
      value: parseUnits(ftmAmount, 18),
    };
    return await TaxOffice.addLiquidityETHTaxFree(
      tombAmount,
      tombAmount.mul(992).div(1000),
      parseUnits(ftmAmount, 18).mul(992).div(1000),
      overrides
    );
  }

  async quoteFromSpooky(
    tokenAmount: string,
    tokenName: string
  ): Promise<string> {
    const { SpookyRouter } = this.contracts;
  
    const { _reserve0, _reserve1 } = await this.TOMBWETH_LP.getReserves();
 
    let quote;
    if (tokenName === "GRAVE") {
      quote = await SpookyRouter.quote(
        parseUnits(tokenAmount),
        _reserve1,
        _reserve0
      );
    } else {
      quote = await SpookyRouter.quote(
        parseUnits(tokenAmount),
        _reserve0,
        _reserve1
      );
    }
    return (quote / 1e18).toString();
  }

  /**
   * @returns an array of the regulation events till the most up to date epoch
   */
  async listenForRegulationsEvents(): Promise<any> {
    const { Treasury } = this.contracts;

    const treasuryDaoFundedFilter = Treasury.filters.DaoFundFunded();
    const treasuryDevFundedFilter = Treasury.filters.DevFundFunded();
    const treasuryMasonryFundedFilter = Treasury.filters.MasonryFunded();
    const boughtBondsFilter = Treasury.filters.BoughtBonds();
    const redeemBondsFilter = Treasury.filters.RedeemedBonds();

    let epochBlocksRanges: any[] = [];
    let masonryFundEvents = await Treasury.queryFilter(
      treasuryMasonryFundedFilter
    );
    var events: any[] = [];
    masonryFundEvents.forEach(function callback(value, index) {
      events.push({ epoch: index + 1 });
      events[index].masonryFund = getDisplayBalance(value.args[1]);
      if (index === 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
      }
      if (index > 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
        epochBlocksRanges[index - 1].endBlock = value.blockNumber;
      }
    });

    epochBlocksRanges.forEach(async (value, index) => {
      events[index].bondsBought = await this.getBondsWithFilterForPeriod(
        boughtBondsFilter,
        value.startBlock,
        value.endBlock
      );
      events[index].bondsRedeemed = await this.getBondsWithFilterForPeriod(
        redeemBondsFilter,
        value.startBlock,
        value.endBlock
      );
    });
    let DEVFundEvents = await Treasury.queryFilter(treasuryDevFundedFilter);
    DEVFundEvents.forEach(function callback(value, index) {
      events[index].devFund = getDisplayBalance(value.args[1]);
    });
    let DAOFundEvents = await Treasury.queryFilter(treasuryDaoFundedFilter);
    DAOFundEvents.forEach(function callback(value, index) {
      events[index].daoFund = getDisplayBalance(value.args[1]);
    });
    return events;
  }

  /**
   * Helper method
   * @param filter applied on the query to the treasury events
   * @param from block number
   * @param to block number
   * @returns the amount of bonds events emitted based on the filter provided during a specific period
   */
  async getBondsWithFilterForPeriod(
    filter: EventFilter,
    from: number,
    to: number
  ): Promise<number> {
    const { Treasury } = this.contracts;
    const bondsAmount = await Treasury.queryFilter(filter, from, to);
    return bondsAmount.length;
  }

  async estimateZapIn(
    tokenName: string,
    lpName: string,
    amount: string
  ): Promise<number[]> {
    const { zapper } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let estimate;
    if (tokenName === WETH_TICKER) {
      estimate = await zapper.estimateZapIn(
        lpToken.address,
        UNISWAPV2_ROUTER_ADDR,
        parseUnits(amount, 18)
      );
    } else {
      const token = tokenName === TOMB_TICKER ? this.GRAVE : this.SKULL;
      estimate = await zapper.estimateZapInToken(
        token.address,
        lpToken.address,
        UNISWAPV2_ROUTER_ADDR,
        parseUnits(amount, 18)
      );
    }
    return [estimate[0] / 1e18, estimate[1] / 1e18];
  }
  async zapIn(
    tokenName: string,
    lpName: string,
    amount: string
  ): Promise<TransactionResponse> {
    const { zapper } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    if (tokenName === WETH_TICKER) {
      let overrides = {
        value: parseUnits(amount, 18),
      };
      return await zapper.zapIn(
        lpToken.address,
        UNISWAPV2_ROUTER_ADDR,
        this.myAccount,
        overrides
      );
    } else {
      const token = tokenName === TOMB_TICKER ? this.GRAVE : this.SKULL;
      return await zapper.zapInToken(
        token.address,
        parseUnits(amount, 18),
        lpToken.address,
        UNISWAPV2_ROUTER_ADDR,
        this.myAccount
      );
    }
  }
  async swapTBondToSkull(tbondAmount: BigNumber): Promise<TransactionResponse> {
    const { SkullSwapper } = this.contracts;
    return await SkullSwapper.swapTBondToSkull(tbondAmount);
  }
  async estimateAmountOfSkull(tbondAmount: string): Promise<string> {
    const { SkullSwapper } = this.contracts;
    try {
      const estimateBN = await SkullSwapper.estimateAmountOfSkull(
        parseUnits(tbondAmount, 18)
      );
      return getDisplayBalance(estimateBN, 18, 6);
    } catch (err) {
      console.error(`Failed to fetch estimate Skull amount: ${err}`);
    }
  }

  async getSkullSwapperStat(address: string): Promise<SkullSwapperStat> {
    const { SkullSwapper } = this.contracts;
    const SkullBalanceBN = await SkullSwapper.getSkullBalance();
    const tbondBalanceBN = await SkullSwapper.getTBondBalance(address);
    // const tombPriceBN = await SkullSwapper.getGravePrice();
    // const SkullPriceBN = await SkullSwapper.getSkullPrice();
    const rateSkullPerTombBN = await SkullSwapper.getSkullAmountPerTomb();
    const SkullBalance = getDisplayBalance(SkullBalanceBN, 18, 5);
    const tbondBalance = getDisplayBalance(tbondBalanceBN, 18, 5);
    return {
      skullBalance: SkullBalance.toString(),
      tbondBalance: tbondBalance.toString(),
      // tombPrice: tombPriceBN.toString(),
      // SkullPrice: SkullPriceBN.toString(),
      rateSkullPerTomb: rateSkullPerTombBN.toString(),
    };
  }
}
