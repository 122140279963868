// import { ChainId } from '@pancakeswap-libs/sdk';
// import { ChainId } from '@spookyswap/sdk';
import { ChainId } from "./sdk/dist/constants.d";
import { Configuration } from "./tomb-finance/config";
import { BankInfo } from "./tomb-finance";

const configurations: { [env: string]: Configuration } = {
  development: {
    chainId: 8453,
    networkName: "Base",
    ftmscanUrl: "https://basescan.org/",
    defaultProvider: "https://base-mainnet.g.alchemy.com/v2/XBXj0YoB341HQwluSf9CloSZ8uApvuaV",
    deployments: require("./tomb-finance/deployments/deployments.testing.json"),
    externalTokens: {
      WETH: ["0x4200000000000000000000000000000000000006", 18],
      USDbC: ["0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA", 6],
      DAI: ["0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb", 18],
      "DAI-WETH-LP": ["0x0FeB1490f80B6978002c3E501753562f2F2853B2", 18],
      "GRAVE-WETH-LP": ["0x7954802A069C6f775A00921140683B72708F2A65", 18],
      "SKULL-WETH-LP": ["0x7954802A069C6f775A00921140683B72708F2A65", 18],
      "GRAVE-SKULL-LP": ["0x7954802A069C6f775A00921140683B72708F2A65", 18],
    },
    baseLaunchDate: new Date("2021-06-02 13:00:00Z"),
    bondLaunchesAt: new Date("2020-12-03T15:00:00Z"),
    masonryLaunchesAt: new Date("2020-12-11T00:00:00Z"),
    refreshInterval: 10000,
  },
  production: {
    chainId: 8453,
    networkName: "Base",
    ftmscanUrl: "https://basescan.org/",
    defaultProvider: "https://base-mainnet.g.alchemy.com/v2/XBXj0YoB341HQwluSf9CloSZ8uApvuaV",
    deployments: require("./tomb-finance/deployments/deployments.testing.json"),
    externalTokens: {
      WETH: ["0x4200000000000000000000000000000000000006", 18],
      USDbC: ["0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA", 6],
      DAI: ["0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb", 18],
      "DAI-WETH-LP": ["0x0FeB1490f80B6978002c3E501753562f2F2853B2", 18],
      "GRAVE-WETH-LP": ["0x7954802A069C6f775A00921140683B72708F2A65", 18],
      "SKULL-WETH-LP": ["0x7954802A069C6f775A00921140683B72708F2A65", 18],
      "GRAVE-SKULL-LP": ["0x7954802A069C6f775A00921140683B72708F2A65", 18],
    },
    baseLaunchDate: new Date("2021-06-02 13:00:00Z"),
    bondLaunchesAt: new Date("2020-12-03T15:00:00Z"),
    masonryLaunchesAt: new Date("2020-12-11T00:00:00Z"),
    refreshInterval: 10000,
  },
};

export const genesisDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding GRAVE
        - 2 = LP asset staking rewarding SKULL
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
 
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding GRAVE
        - 2 = LP asset staking rewarding SKULL
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */

  // GENESIS

  GenesisWethRewardPool: {
    name: "Stake wETH, earn GRAVE",
    poolId: 0,
    sectionInUI: 0,
    contract: "GenesisWethRewardPool",
    depositTokenName: "WETH",
    earnTokenName: "GRAVE",
    multiplier: "50x",
    finished: false,
    sort: 1,
    closedForStaking: true,
    genesisFinished: false,
  },
  GenesisUsdcRewardPool: {
    name: "Stake USDbC, earn GRAVE",
    poolId: 1,
    sectionInUI: 0,
    contract: "GenesisUsdcRewardPool",
    depositTokenName: "USDbC",
    earnTokenName: "GRAVE",
    multiplier: "25x",
    finished: false,
    sort: 3,
    closedForStaking: true,
    genesisFinished: false,
  },
  GenesisDAIRewardPool: {
    name: "Stake DAI, earn GRAVE",
    poolId: 2,
    sectionInUI: 0,
    contract: "GenesisDAIRewardPool",
    depositTokenName: "DAI",
    earnTokenName: "GRAVE",
    multiplier: "25x",
    finished: false,
    sort: 4,
    closedForStaking: true,
    genesisFinished: false,
  },

  //
  //
  //
  //
  //

  //Earn GRAVE

   DAIGraveRewardPool: {
    name: "Stake DAI, earn GRAVE",
    poolId: 2,
    sectionInUI: 1,
    contract: "DAIGraveRewardPool",
    depositTokenName: "DAI",
    earnTokenName: "GRAVE",
    multiplier: "5x",
    finished: false,
    sort: 5,
    closedForStaking: false,
  },


   UsdcGraveRewardPool: {
    name: "Stake USDbC, earn GRAVE",
    poolId: 1,
    sectionInUI: 1,
    contract: "UsdcGraveRewardPool",
    depositTokenName: "USDbC",
    earnTokenName: "GRAVE",
    multiplier: "5x",
    finished: false,
    sort: 5,
    closedForStaking: false,
  },


  GraveWethLPGraveRewardPool: {
    name: "Earn GRAVE by GRAVE-WETH",
    poolId: 0,
    sectionInUI: 1,
    contract: "GraveWethLPGraveRewardPool",
    depositTokenName: "GRAVE-WETH-LP",
    earnTokenName: "GRAVE",
    multiplier: "100x",
    finished: false,
    sort: 5,
    closedForStaking: false,
  },


  //END SECTION OF Earn GRAVE

  //Earn SKULL
  GraveWethLPSkullRewardPool: {
    name: "Earn SKULL by GRAVE-WETH LP",
    poolId: 0,
    sectionInUI: 2,
    contract: "GraveWethLPSkullRewardPool",
    depositTokenName: "GRAVE-WETH-LP",
    earnTokenName: "SKULL",
    multiplier: "3000x",
    finished: true,
    sort: 6,
    closedForStaking: true,
  },
  SkullWethLPTSkullRewardPool: {
    name: "Earn SKULL by SKULL-WETH LP",
    poolId: 1,
    sectionInUI: 2,
    contract: "SkullWethLPTSkullRewardPool",
    depositTokenName: "SKULL-WETH-LP",
    earnTokenName: "SKULL",
    multiplier: "2400x",
    finished: true,
    sort: 7,
    closedForStaking: true,
  },
  GraveSkullLPSkullRewardPool: {
    name: "Earn SKULL by GRAVE-SKULL LP",
    poolId: 2,
    sectionInUI: 2,
    contract: "GraveSkullLPSkullRewardPool",
    depositTokenName: "GRAVE-SKULL-LP",
    earnTokenName: "SKULL",
    multiplier: "550x",
    finished: true,
    sort: 8,
    closedForStaking: true,
  },
};

//END SECTION OF EARN SKULL

export default configurations["production"];
